import React, { useContext, useEffect } from "react";
import { Context } from "redux/Store";
import { getUrlFromSlug } from "libs/services/utils";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import ProductDetails from "common/product-details";
import AccordionContent from "common/accordion";
import { TypeA, TypeC } from "common/callouts";
import useGoToAnchor from "libs/hooks/useGoToAnchor";

import frontImage from "images/sun/sunproducts/oilcontrol-product-front.webp";
import frontCapImage from "images/sun/sunproducts/oilcontrol-product-front-cap.webp";
import backImage from "images/sun/sunproducts/oilcontrol-product-back.webp";


import frontZoomImage from "images/sun/sunproducts/oilcontrol-product-front-zoom.webp";
import frontCapZoomImage from "images/sun/sunproducts/oilcontrol-product-front-cap-zoom.webp";
import backZoomImage from "images/sun/sunproducts/oilcontrol-product-back-zoom.webp";

// import img1 from "images/sun/sunproducts/oilcontrol-research-results.webp";
// import img2 from "images/sun/sunproducts/oilcontrol-finish.webp";
import img3 from "images/sun/sunproducts/oilcontrol-finish-4-16.webp";
import img4 from "images/sun/sunproducts/oilcontrol-patient-feedback-4-16.webp";

import img8 from "images/callouts/cout-patient-resource.webp";
import img9 from "images/callouts/cout-footer-dry-skin-therapy.webp";
import img10 from "images/callouts/cout-footer-5aox.webp";
import img11 from "images/callouts/cout-footer-journal.webp";

import "./oilcontrol.scss";

function OilControlPage({ pageContext }) {
  const { state, dispatch } = useContext(Context);

  useGoToAnchor();

  return (
    <Layout
      pageClass="page-sun-eucerinsunproducts-oil-control page-level-3"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Eucerin Sun Oil Control"
        pageDescription="Learn about Eucerin Sun Oil Control for oily-prone skin"
      />
      <div className="inner-body-content">
        <div className="top-content-level-3">
          <section className="inner-centered-container">
            <ProductDetails
              categoryBgColor="#E07810"
              categoryName="SUN CARE"
              categoryFontColor="#ffffff"
              productName="Eucerin<sup>®</sup> Sun Clear Skin"
              productSlider={[frontCapImage, frontImage, backImage]}
              zoomImages={[frontCapZoomImage, frontZoomImage, backZoomImage]}
            >
              <div className="functions">
                <p className="font-semi-bold mango-tango-text sub-title">
                  Photoaging* sun protection with oil control
                </p>
                <p>
                  <span className="font-semi-bold">Eucerin Sun Clear Skin</span>{" "}
                  is formulated with innovative oil control technology to help
                  maintain clear skin, with an invisible, dry matte finish.
                  Works well alone or under makeup.
                </p>
                <ul className="mango-tango-bullets">
                  <li>
                    <span>Blends seamlessly with all skin tones</span>
                  </li>
                  <li>
                    <span>
                      <span className="font-semi-bold">
                        {" "}
                        Formulation helps defend against free radicals
                      </span>
                    </span>
                  </li>
                  <li>
                    <span>Mattifying effect for a non-shiny face</span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients">
                <p className="font-semi-bold mango-tango-text sub-title">
                  Active ingredients
                </p>
                <p className="no-mb font-semi-bold">
                  Avobenzone 3%, homosalate 10%, octisalate 5%, octocrylene 10%
                </p>
                <ul className="mango-tango-bullets">
                  <li>
                    <span>UVA/UVB broad-spectrum protection</span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients no-brd">
                <p className="font-semi-bold mango-tango-text sub-title">
                  Key ingredients
                </p>
                <p className="no-mb font-semi-bold">
                  5 AOX Shield<sup>&trade;</sup> helps to:
                </p>
                <ul className="mango-tango-bullets">
                  <li>
                    <span>Neutralize free radicals</span>
                  </li>
                  <li>
                    <span>Guard against free radical formation</span>
                  </li>
                  <li>
                    <span>Defend against natural antioxidant breakdown</span>
                  </li>
                </ul>
                <p className="no-mb font-semi-bold">
                  Innovative oil control; the 3 oil-absorbing minerals help
                  provide:
                </p>
                <ul className="mango-tango-bullets">
                  <li>
                    <span>An immediate and long-lasting* mattifying effect</span>
                  </li>
                  <li>
                    <span>Dry-touch texture</span>
                  </li>
                </ul>
              </div>
            </ProductDetails>
            <Row>
              <Col xs={12}>
                <div className="features">
                  <p className="font-semi-bold mango-tango-text sub-title">
                    Formulated for <nobr>acne-prone</nobr> skin
                  </p>
                  <ul className="checkmarks mango-tango">
                    <li>
                      <span>Hypoallergenic</span>
                    </li>
                    <li>
                      <span>Non-comedogenic</span>
                    </li>
                    <li>
                      <span>Ophthalmologist-tested</span>
                    </li>
                  </ul>
                  <ul className="checkmarks mango-tango">
                    <li>
                      <span>Fragrance-free</span>
                    </li>
                    <li>
                      <span>Octinoxate-free</span>
                    </li>
                    <li>
                      <span>Oxybenzone-free</span>
                    </li>
                  </ul>
                </div>
                <p className="references-symbols last">
                  *Use as directed with other sun protection measures (see Drug
                  Facts Box).
                </p>
              </Col>
            </Row>
          </section>
        </div>
        <div className="gradient-bg">
          <section className="inner-centered-container">
            {/* <AccordionContent
              accordionHeading="RESEARCH RESULTS"
              productThemeColor="sun-mango-tango"
              id="reseachresults"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img1}
                    className="accordion-chart"
                    alt="Research result showing protection against damage induced by UVA1 and VL"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> 10 subjects
                    with FST IV-VI enrolled (9 completed the study), with
                    exposure of 380 J/cm<sup>2</sup> of 2% UVA1 and visible
                    light with pretreatment 24 hours prior to exposure and
                    product reapplication 20 minutes prior to exposure.
                    Assessments conducted immediately, 24 hours, and 7 days
                    after exposure by diffuse reflectance spectroscopy,
                    cross-polarized images, and expert grading.
                  </p>
                  <p className="references">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Provides sun
                    <br className="show-desktop" /> protection on
                    <br className="show-desktop" /> parity with a tinted{" "}
                    <br className="show-desktop" /> mineral sunscreen{" "}
                    <br className="show-desktop" /> with iron oxide<sup>1</sup>
                  </div>
                </Col>
              </Row>
            </AccordionContent> */}
            <AccordionContent
              accordionHeading="FINISH COMPARISON"
              productThemeColor="sun-mango-tango"
              id="finishcomparison"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  {/* <img
                    src={img2}
                    className="accordion-chart"
                    alt="Showing oil control blend on skin vs tinted mineral sunscreen"
                  /> */}
                  <img
                    src={img3}
                    className="accordion-chart"
                    alt="Showing oil control blend on skin vs leading mineral sunscreen"
                  />
                  <p className="references">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Cosmetically elegant; <br className="show-desktop" />{" "}
                    seamlessly blends on <br className="show-desktop" />
                    all skin tones
                  </div>
                </Col>
              </Row>
            </AccordionContent>
            <AccordionContent
              accordionHeading="SURVEY RESULTS"
              productThemeColor="sun-mango-tango"
              id="surveyresults"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img4}
                    className="accordion-chart"
                    alt="Survey result presenting postive feedback on Eucerin Sun Oil Control"
                  />
                  <p className="references">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Effective sun <br className="show-desktop" /> protection for{" "}
                    <br className="show-desktop" /> <nobr>acne-prone</nobr>{" "}
                    faces <br className="show-desktop" /> of any skin tone
                  </div>
                </Col>
              </Row>
            </AccordionContent>
          </section>
        </div>
        <section className="callouts-container gradient-bg section-support-resource orange">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={8} order={{ xs: 2, md: 1 }} className="">
                <TypeC
                  buttonText="DOWNLOAD HERE"
                  buttonUrl={getUrlFromSlug(
                    "eucerin-sun-patient-brochure",
                    state?.sanityData?.allResources
                  )}
                  className="center-v"
                  isExternalLink={true}
                  targetOpen="_blank"
                >
                  <p className="reg-text-big">
                    Support your recommendation <br className="show-desktop" />{" "}
                    with this resource
                  </p>
                  <p className="reg-text">
                    Provide your patients with sun protection tips and more.
                  </p>
                </TypeC>
              </Col>
              <Col sm={12} md={4} order={{ xs: 1, md: 2 }} className="">
                <img
                  src={img8}
                  alt="Everyone under the sun needs protection"
                  className="cout-product-img"
                />
              </Col>
            </Row>
          </div>
        </section>
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img9}
                  imgAlt="Hydration"
                  caption="Recommend daily hydration <br class='show-desktop' /> along with sun protection"
                  buttonText="Explore Body Care"
                  buttonUrl="/body"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img10}
                  imgAlt="5AOX Shield"
                  caption="Formulas to help defend against damage from oxidative stress"
                  buttonText="See the modes of defense"
                  buttonUrl="/sun/freeradicaldefense"
                  imgStyles={{ height: 165 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img11}
                  imgAlt="JAAD"
                  caption="Discover how perspectives in sun protection are evolving"
                  buttonText="VIEW JOURNAL ARTICLES"
                  buttonUrl="https://www.jaad.org/issue/S0190-9622(22)X0002-9"
                  imgStyles={{ height: 165 }}
                  isExternalLink={true}
                  targetOpen="_blank"
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default OilControlPage;
